/// <reference path="../../typings/index.d.ts" />

$(function() {

    $(window).scroll(function(){                              
        if ( $(window).scrollTop() > 0  ){                   
            $('body').addClass('scrolled');
        } else {
            $('body').removeClass('scrolled');
        }
    });

// кнопка наверх
	$("#back-top").hide();
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 500) {
				$('#back-top').fadeIn();
			} else {
				$('#back-top').fadeOut();
			}
		});

		$('#back-top').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 400);
			return false;
		});
	});

//Слайдер клиентов
if($('.clients-slider').length) {
    $('.clients-slider').carouFredSel({
        circular:true,
        infinite:true,
        responsive:false,
        height:40,
        scroll:{
            items:1,
        },
        auto:true,
        items:{
        },
        swipe:{
            onTouch:true
        }
    });
    $(window).resize(function() {
        $('.clients-slider').trigger('updateSizes');
    });
}

//Слайдер в баннере

let quantity = $('#banner').children().length;
let quantity_string;
if(quantity >= 10) {
    quantity_string = quantity;
} else {
    quantity_string = '0' + quantity;
}

$('.banner #number').html(quantity_string);

if($('#banner').length) {
    $('#banner').carouFredSel({
        circular:true,
        infinite:true,
        responsive:false,
        width: 1920,
        height:520,
        scroll:{
            items:1,

            onBefore: function(data) {
                let index = data.items.visible.eq(0).attr('data-index');
                let index_string;

                if(index >= 10) {
                    index_string = index;
                } else {
                    index_string = '0' + index;
                }

                $('.banner #count').html(index_string);
            }
        },
        auto:{
            play: true,
            timeoutDuration: 5000
        },
        items:{
        },
        swipe:{
            onTouch:true
        },
        prev: '#banner_prev',
        next: '#banner_next'
    });
    $(window).resize(function() {
        $('.clients-slider').trigger('updateSizes');
    });
}

//анимация input
    $('label.animation input').focus(function() {
        $(this).closest('label').addClass('filled');
    });
    $('label.animation input').blur(function() {
        if($(this).val() == "") {
            $(this).closest('label').removeClass('filled');
        }
    });


//всплывающие окна

    $('.popup_show').click(function(e) {
        e.preventDefault();
        $('.popup_background').addClass('show');
        $('.popups').addClass('show');
        $($(this).attr('href')).addClass('show');        
        //$('html').css('overflow', 'hidden');

        if($(this).hasClass('order')) {
            $('form[name="metering"]').find('input[name="item"]').val($(this).closest('.item').find('.title').html());
        }
    });

    $('.popup .close').click(function(e) {
        e.preventDefault();
        closePopups();
    });

    $('.popup .close_button').click(function(e) {
        e.preventDefault();
        closePopups();
    });

    $('.popup_background').click(function(e) {
        e.preventDefault();
        closePopups();
    });


//маска номера
    $('input[name="phone"]').focus(function() {
        if($(this).val() == "") {
            $(this).val('+7 (');
        }   
      });

      $('input[name="phone"]').focusout(function() {
          $(this).mask("+7 (000) 000-00-00", {
            clearIfNotMatch: true
          });
      });

    $('input[name="phone"]').bind("change keyup input", function() { 

        var num = $(this).val().replace(/\D+/g,""); 
        
        if(num.indexOf("789") === 0 || num.indexOf("77") === 0 || num.indexOf("89") === 0 || num === "" || num === "7") {

            if(num.indexOf("789") === 0 || num.indexOf("77") === 0) {    
                num = num.substr(2 - num.length, num.length - 2);
            }

            if(num.indexOf("89") === 0) { 
                num = num.substr(1 - num.length, num.length - 1);
            }

            if(num === "7") {
                num = "";
            }

            num = "+7 (" + num;
            $(this).val(num);
            $(this).mask("+7 (000) 000-00-00", {
                clearIfNotMatch: true
              });
            var b = $(this).val();
            $(this).val(b);
        }


      });
      
      $('input[name="phone"]').mask("+7 (000) 000-00-00", {
        clearIfNotMatch: true
      });

//всплывающие подсказки
if($('.question').length) {
    $('.question').click(function(e) {
        e.preventDefault();
        if($(this).siblings().is(".answer")) {
            $(this).closest('.hint').addClass('open');
            let answer = $(this).siblings('.answer');
            $(document).mouseup(function (e){ 
                if (!answer.is(e.target) // если клик был не по нашему блоку
                    && answer.has(e.target).length === 0) { // и не по его дочерним элементам
                    answer.closest('.hint').removeClass('open');
                }
            });
        }
    });
}

//"показать полностью" для сео текста
if($('.seo_text').length) {
    let seo_height = $('.seo_text .preview').height() + "px";
    $('.seo_text .preview').addClass('short');
     $('.seo_text .preview').css({'maxHeight': '100px'});
    $('.show_full_seo').click(function(e) {
        e.preventDefault();
        if(!$(this).siblings('.preview').hasClass('short')) {
            $(this).siblings('.preview').addClass('short');            
            $(this).siblings('.preview').animate({'maxHeight': '100px'}, 100);
            $(this).html('Показать полностью');
        } else {
            $(this).siblings('.preview').removeClass('short');
            $(this).siblings('.preview').animate({'maxHeight': seo_height}, 100);
            $(this).html('Скрыть');
        }
    });
}
    

//Слайдер фото в карточке товара
if($('.photos-slider').length) {
    $('.photos-slider').carouFredSel({
        circular:true,
        infinite:true,
        responsive:false,
        height:430,
		prev: '#photos-left',
		next: '#photos-right',
        scroll:{
            items:1,
        },
        auto:false,
        items:{
            visible: {
                min:1,
                max:1
            }
        },
        swipe:{
            onTouch:true
        }
    });
}

//Слайдер товаров на главной
if($('.products-slider').length) {
    $('.products-slider').carouFredSel({
        circular:true,
        infinite:true,
        responsive:false,
		prev: '#products-left',
		next: '#products-right',
        width: 1200,
        scroll:{
            items:1,
        },
        auto:false,
        items:{
            visible: {
                min:1,
                max:1
            }
        },
        swipe:{
            onTouch:true
        }
    });
}

//Слайдер цветов
if($('.colors-slider').length) {
    $('.colors-slider').children().eq(3).addClass('big');
    $('.colors-slider').carouFredSel({
        circular:true,
        infinite:true,
        responsive:false,
		prev: '#colors-left',
		next: '#colors-right',
        scroll:{
            items:1,
            duration: 700,
            onBefore: function( data ) {
				if(data.scroll.direction == "next") {
					data.items.old.eq( 3 ).removeClass('big');
					data.items.old.eq( 4 ).addClass('big');
		        } else {                    
					data.items.old.eq( 3 ).removeClass('big');
					data.items.old.eq( 2 ).addClass('big');
                }
            },
        },
        auto: true,
        items:{
            visible: {
                min:7,
                max:7
            }
        },
        swipe:{
            onTouch:true
        }
    });
}

//Слайдер фактур
if($('.mdf-slider').length) {
    $('.mdf-slider').children().eq(3).addClass('big');
    $('.mdf-slider').carouFredSel({
        circular:true,
        infinite:true,
        responsive:false,
		prev: '#mdf-left',
		next: '#mdf-right',
        scroll:{
            items:1,
            duration: 700,
            onBefore: function( data ) {
				if(data.scroll.direction == "next") {
					data.items.old.eq( 3 ).removeClass('big');
					data.items.old.eq( 4 ).addClass('big');
		        } else {                    
					data.items.old.eq( 3 ).removeClass('big');
					data.items.old.eq( 2 ).addClass('big');
                }
            },
        },
        auto: true,
        items:{
            visible: {
                min:7,
                max:7
            }
        },
        swipe:{
            onTouch:true
        }
    });
}

//Слайдер рисунков
if($('.pictures-slider').length) {
    $('.pictures-slider').children().eq(3).addClass('big');
    $('.pictures-slider').carouFredSel({
        circular:true,
        infinite:true,
        responsive:false,
		prev: '#pictures-left',
		next: '#pictures-right',
        scroll:{
            items:1,
            duration: 700,
            onBefore: function( data ) {
				if(data.scroll.direction == "next") {
					data.items.old.eq( 3 ).removeClass('big');
					data.items.old.eq( 4 ).addClass('big');
		        } else {                    
					data.items.old.eq( 3 ).removeClass('big');
					data.items.old.eq( 2 ).addClass('big');
                }
            },
        },
        auto: true,
        items:{
            visible: {
                min:7,
                max:7
            }
        },
        swipe:{
            onTouch:true
        }
    });
}


//Навигация в блоке безопасность
if($('.safety').length) {
    $('.safety a.nav').click(function(e) {
        e.preventDefault();

        if(!$(this).hasClass('active')) {
            $('.safety a.nav').removeClass('active');
            $(this).addClass('active');
        } 
    });
    $('.safety a.nav:first').addClass('active');
}

//Навигация в блоке теплошумоизоляции
if($('.isolation').length) {
    $('.isolation a.nav').click(function(e) {
        e.preventDefault();

        if(!$(this).hasClass('active')) {
            $('.isolation a.nav').removeClass('active');
            $(this).addClass('active');
        } 

        if(!$($(this).attr('href')).hasClass('active')) {
            $('.isolation .content').removeClass('active');
            $($(this).attr('href')).addClass('active');
        }
    });
    $('.isolation .content:first').addClass('active');

    $('#isolation-left').click(function(e){
        e.preventDefault();
        //$('.isolation .scroller').scrollLeft($('.isolation .scroller').scrollLeft()-297);
        $('.isolation .scroller').animate({scrollLeft: '-=297',}, 500);
    });

    $('#isolation-right').click(function(e){
        e.preventDefault();
        // $('.isolation .scroller').scrollLeft($('.isolation .scroller').scrollLeft()+297);
        $('.isolation .scroller').animate({scrollLeft: '+=297',}, 500);
    });
}


//Навигация в блоке декора
if($('.decor').length) {
    $('.decor a.nav_a').click(function(e) {
        e.preventDefault();

        if(!$(this).hasClass('active')) {
            $('.decor a.nav_a').removeClass('active');
            $(this).addClass('active');
        } 
    });
    $('.decor a.nav_a:first').addClass('active');
}

//анимация баннера на странице строительным компаниям
if($('#building-company_banner').length) {
    $(document).scroll(function () {
        let s_top = $(document).scrollTop() + $(window).height() - 350;
        let yes = $("#building-company_banner").offset().top;
        if(s_top > yes){
            $("#building-company_banner").find('form').addClass('show');
            $("#building-company_banner").find('.line').addClass('show');
        }
    });
}

//скролл
if($('.scroll').length) {
    $('.scroll').click(function(e) {
        e.preventDefault();
        let scroll_el = $(this).attr('href'); 
        if ($(scroll_el).length != 0) { 
            $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500); 
        }
    });
}


    // $('.scroller').jScrollPane();

}); //$(function()

function closePopups() {
    $('.popup_background').removeClass('show');       
    $('.popups').removeClass('show'); 
    $('.popup').removeClass('show');  
    $('form[name="metering"]').find('input[name="item"]').val('');
    //$('html').css('overflow', '');       
}

function showSuccess() { 
    closePopups();     
    $('.popup_background').addClass('show');
    $('.popups').addClass('show');
    $('.popup.thanks').addClass('show');
}


    